<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Tariffs
      <router-link slot="right" :to="{ name: 'biller' }" class="button">
        New Tariff
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tariff-table :checkable="true"/>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import TariffTable from '@/components/SSIPLENGG/TariffTable.vue'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Tariffs',
  components: {
    HeroBar,
    TitleBar,
    TariffTable
  },
  computed: {
    titleStack () {
      return ['Admin', 'Tariffs']
    }
  }
}
</script>
